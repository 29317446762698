/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
var validator_pb = require('./validator_pb.js');
goog.exportSymbol('proto.services.AddContactRequest', null, global);
goog.exportSymbol('proto.services.AddContactResponse', null, global);
goog.exportSymbol('proto.services.AddDiscussionRequest', null, global);
goog.exportSymbol('proto.services.AddDiscussionResponse', null, global);
goog.exportSymbol('proto.services.Chain', null, global);
goog.exportSymbol('proto.services.ConnectNodeRequest', null, global);
goog.exportSymbol('proto.services.ConnectNodeResponse', null, global);
goog.exportSymbol('proto.services.ContactInfo', null, global);
goog.exportSymbol('proto.services.CreateInvoiceRequest', null, global);
goog.exportSymbol('proto.services.CreateInvoiceResponse', null, global);
goog.exportSymbol('proto.services.DiscussionInfo', null, global);
goog.exportSymbol('proto.services.DiscussionOptions', null, global);
goog.exportSymbol('proto.services.EstimateMessageRequest', null, global);
goog.exportSymbol('proto.services.EstimateMessageResponse', null, global);
goog.exportSymbol('proto.services.GetContactsRequest', null, global);
goog.exportSymbol('proto.services.GetContactsResponse', null, global);
goog.exportSymbol('proto.services.GetDiscussionHistoryByIDRequest', null, global);
goog.exportSymbol('proto.services.GetDiscussionHistoryResponse', null, global);
goog.exportSymbol('proto.services.GetDiscussionStatisticsRequest', null, global);
goog.exportSymbol('proto.services.GetDiscussionStatisticsResponse', null, global);
goog.exportSymbol('proto.services.GetDiscussionsRequest', null, global);
goog.exportSymbol('proto.services.GetDiscussionsResponse', null, global);
goog.exportSymbol('proto.services.GetNodesRequest', null, global);
goog.exportSymbol('proto.services.HopHint', null, global);
goog.exportSymbol('proto.services.Invoice', null, global);
goog.exportSymbol('proto.services.InvoiceHTLC', null, global);
goog.exportSymbol('proto.services.InvoiceHTLCState', null, global);
goog.exportSymbol('proto.services.InvoiceState', null, global);
goog.exportSymbol('proto.services.KeySetPageOptions', null, global);
goog.exportSymbol('proto.services.LookupInvoiceRequest', null, global);
goog.exportSymbol('proto.services.LookupInvoiceResponse', null, global);
goog.exportSymbol('proto.services.Message', null, global);
goog.exportSymbol('proto.services.MessageOptions', null, global);
goog.exportSymbol('proto.services.NodeInfo', null, global);
goog.exportSymbol('proto.services.NodeInfoResponse', null, global);
goog.exportSymbol('proto.services.OpenChannelRequest', null, global);
goog.exportSymbol('proto.services.OpenChannelResponse', null, global);
goog.exportSymbol('proto.services.PaymentHop', null, global);
goog.exportSymbol('proto.services.PaymentRoute', null, global);
goog.exportSymbol('proto.services.RemoveContactByAddressRequest', null, global);
goog.exportSymbol('proto.services.RemoveContactByIDRequest', null, global);
goog.exportSymbol('proto.services.RemoveContactResponse', null, global);
goog.exportSymbol('proto.services.RemoveDiscussionRequest', null, global);
goog.exportSymbol('proto.services.RemoveDiscussionResponse', null, global);
goog.exportSymbol('proto.services.RouteHint', null, global);
goog.exportSymbol('proto.services.SearchNodeByAddressRequest', null, global);
goog.exportSymbol('proto.services.SearchNodeByAliasRequest', null, global);
goog.exportSymbol('proto.services.SelfBalanceRequest', null, global);
goog.exportSymbol('proto.services.SelfBalanceResponse', null, global);
goog.exportSymbol('proto.services.SelfInfoRequest', null, global);
goog.exportSymbol('proto.services.SelfInfoResponse', null, global);
goog.exportSymbol('proto.services.SendMessageRequest', null, global);
goog.exportSymbol('proto.services.SendMessageResponse', null, global);
goog.exportSymbol('proto.services.SubscribeMessageRequest', null, global);
goog.exportSymbol('proto.services.SubscribeMessageResponse', null, global);
goog.exportSymbol('proto.services.UpdateDiscussionLastReadRequest', null, global);
goog.exportSymbol('proto.services.UpdateDiscussionResponse', null, global);
goog.exportSymbol('proto.services.Version', null, global);
goog.exportSymbol('proto.services.VersionRequest', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.KeySetPageOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.KeySetPageOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.KeySetPageOptions.displayName = 'proto.services.KeySetPageOptions';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.KeySetPageOptions.prototype.toObject = function(opt_includeInstance) {
    return proto.services.KeySetPageOptions.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.KeySetPageOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.KeySetPageOptions.toObject = function(includeInstance, msg) {
    var f, obj = {
      lastId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
      reverse: jspb.Message.getFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.KeySetPageOptions}
 */
proto.services.KeySetPageOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.KeySetPageOptions;
  return proto.services.KeySetPageOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.KeySetPageOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.KeySetPageOptions}
 */
proto.services.KeySetPageOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReverse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.KeySetPageOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.KeySetPageOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.KeySetPageOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.KeySetPageOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getReverse();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint64 last_id = 1;
 * @return {number}
 */
proto.services.KeySetPageOptions.prototype.getLastId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.KeySetPageOptions.prototype.setLastId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 page_size = 2;
 * @return {number}
 */
proto.services.KeySetPageOptions.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.KeySetPageOptions.prototype.setPageSize = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool reverse = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.services.KeySetPageOptions.prototype.getReverse = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.services.KeySetPageOptions.prototype.setReverse = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.VersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.VersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.VersionRequest.displayName = 'proto.services.VersionRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.VersionRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.VersionRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.VersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.VersionRequest.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.VersionRequest}
 */
proto.services.VersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.VersionRequest;
  return proto.services.VersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.VersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.VersionRequest}
 */
proto.services.VersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.VersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.VersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.VersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.VersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.Version = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.Version, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.Version.displayName = 'proto.services.Version';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.Version.prototype.toObject = function(opt_includeInstance) {
    return proto.services.Version.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.Version} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.Version.toObject = function(includeInstance, msg) {
    var f, obj = {
      version: jspb.Message.getFieldWithDefault(msg, 1, ""),
      commit: jspb.Message.getFieldWithDefault(msg, 2, ""),
      commitHash: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.Version}
 */
proto.services.Version.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.Version;
  return proto.services.Version.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.Version} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.Version}
 */
proto.services.Version.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommitHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.Version.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.Version.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.Version} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Version.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCommitHash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.services.Version.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.Version.prototype.setVersion = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string commit = 2;
 * @return {string}
 */
proto.services.Version.prototype.getCommit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.services.Version.prototype.setCommit = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string commit_hash = 3;
 * @return {string}
 */
proto.services.Version.prototype.getCommitHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.services.Version.prototype.setCommitHash = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.NodeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.NodeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.NodeInfo.displayName = 'proto.services.NodeInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.NodeInfo.prototype.toObject = function(opt_includeInstance) {
    return proto.services.NodeInfo.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.NodeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.NodeInfo.toObject = function(includeInstance, msg) {
    var f, obj = {
      alias: jspb.Message.getFieldWithDefault(msg, 1, ""),
      address: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.NodeInfo}
 */
proto.services.NodeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.NodeInfo;
  return proto.services.NodeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.NodeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.NodeInfo}
 */
proto.services.NodeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.NodeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.NodeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.NodeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.NodeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string alias = 1;
 * @return {string}
 */
proto.services.NodeInfo.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.NodeInfo.prototype.setAlias = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.services.NodeInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.services.NodeInfo.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SelfInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SelfInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SelfInfoRequest.displayName = 'proto.services.SelfInfoRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SelfInfoRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SelfInfoRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SelfInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SelfInfoRequest.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SelfInfoRequest}
 */
proto.services.SelfInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SelfInfoRequest;
  return proto.services.SelfInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SelfInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SelfInfoRequest}
 */
proto.services.SelfInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SelfInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SelfInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SelfInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SelfInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.Chain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.Chain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.Chain.displayName = 'proto.services.Chain';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.Chain.prototype.toObject = function(opt_includeInstance) {
    return proto.services.Chain.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.Chain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.Chain.toObject = function(includeInstance, msg) {
    var f, obj = {
      chain: jspb.Message.getFieldWithDefault(msg, 1, ""),
      network: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.Chain}
 */
proto.services.Chain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.Chain;
  return proto.services.Chain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.Chain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.Chain}
 */
proto.services.Chain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetwork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.Chain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.Chain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.Chain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Chain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNetwork();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string chain = 1;
 * @return {string}
 */
proto.services.Chain.prototype.getChain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.Chain.prototype.setChain = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string network = 2;
 * @return {string}
 */
proto.services.Chain.prototype.getNetwork = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.services.Chain.prototype.setNetwork = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SelfInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.SelfInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.services.SelfInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SelfInfoResponse.displayName = 'proto.services.SelfInfoResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.SelfInfoResponse.repeatedFields_ = [2];


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SelfInfoResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SelfInfoResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SelfInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SelfInfoResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      info: (f = msg.getInfo()) && proto.services.NodeInfo.toObject(includeInstance, f),
      chainsList: jspb.Message.toObjectList(msg.getChainsList(),
        proto.services.Chain.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SelfInfoResponse}
 */
proto.services.SelfInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SelfInfoResponse;
  return proto.services.SelfInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SelfInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SelfInfoResponse}
 */
proto.services.SelfInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.NodeInfo;
      reader.readMessage(value,proto.services.NodeInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new proto.services.Chain;
      reader.readMessage(value,proto.services.Chain.deserializeBinaryFromReader);
      msg.addChains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SelfInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SelfInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SelfInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SelfInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.NodeInfo.serializeBinaryToWriter
    );
  }
  f = message.getChainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Chain.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeInfo info = 1;
 * @return {?proto.services.NodeInfo}
 */
proto.services.SelfInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.services.NodeInfo} */ (
    jspb.Message.getWrapperField(this, proto.services.NodeInfo, 1));
};


/** @param {?proto.services.NodeInfo|undefined} value */
proto.services.SelfInfoResponse.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.SelfInfoResponse.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.SelfInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Chain chains = 2;
 * @return {!Array<!proto.services.Chain>}
 */
proto.services.SelfInfoResponse.prototype.getChainsList = function() {
  return /** @type{!Array<!proto.services.Chain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Chain, 2));
};


/** @param {!Array<!proto.services.Chain>} value */
proto.services.SelfInfoResponse.prototype.setChainsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Chain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Chain}
 */
proto.services.SelfInfoResponse.prototype.addChains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Chain, opt_index);
};


proto.services.SelfInfoResponse.prototype.clearChainsList = function() {
  this.setChainsList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SelfBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SelfBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SelfBalanceRequest.displayName = 'proto.services.SelfBalanceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SelfBalanceRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SelfBalanceRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SelfBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SelfBalanceRequest.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SelfBalanceRequest}
 */
proto.services.SelfBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SelfBalanceRequest;
  return proto.services.SelfBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SelfBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SelfBalanceRequest}
 */
proto.services.SelfBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SelfBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SelfBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SelfBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SelfBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SelfBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SelfBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SelfBalanceResponse.displayName = 'proto.services.SelfBalanceResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SelfBalanceResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SelfBalanceResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SelfBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SelfBalanceResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      walletConfirmedSat: jspb.Message.getFieldWithDefault(msg, 1, 0),
      walletUnconfirmedSat: jspb.Message.getFieldWithDefault(msg, 2, 0),
      channelLocalMsat: jspb.Message.getFieldWithDefault(msg, 3, 0),
      channelRemoteMsat: jspb.Message.getFieldWithDefault(msg, 4, 0),
      pendingOpenLocalMsat: jspb.Message.getFieldWithDefault(msg, 5, 0),
      pendingOpenRemoteMsat: jspb.Message.getFieldWithDefault(msg, 6, 0),
      unsettledLocalMsat: jspb.Message.getFieldWithDefault(msg, 7, 0),
      unsettledRemoteMsat: jspb.Message.getFieldWithDefault(msg, 8, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SelfBalanceResponse}
 */
proto.services.SelfBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SelfBalanceResponse;
  return proto.services.SelfBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SelfBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SelfBalanceResponse}
 */
proto.services.SelfBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWalletConfirmedSat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWalletUnconfirmedSat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChannelLocalMsat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChannelRemoteMsat(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPendingOpenLocalMsat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPendingOpenRemoteMsat(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUnsettledLocalMsat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUnsettledRemoteMsat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SelfBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SelfBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SelfBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SelfBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWalletConfirmedSat();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWalletUnconfirmedSat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getChannelLocalMsat();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getChannelRemoteMsat();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getPendingOpenLocalMsat();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getPendingOpenRemoteMsat();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getUnsettledLocalMsat();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getUnsettledRemoteMsat();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
};


/**
 * optional int64 wallet_confirmed_sat = 1;
 * @return {number}
 */
proto.services.SelfBalanceResponse.prototype.getWalletConfirmedSat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.SelfBalanceResponse.prototype.setWalletConfirmedSat = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 wallet_unconfirmed_sat = 2;
 * @return {number}
 */
proto.services.SelfBalanceResponse.prototype.getWalletUnconfirmedSat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.SelfBalanceResponse.prototype.setWalletUnconfirmedSat = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 channel_local_msat = 3;
 * @return {number}
 */
proto.services.SelfBalanceResponse.prototype.getChannelLocalMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.services.SelfBalanceResponse.prototype.setChannelLocalMsat = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 channel_remote_msat = 4;
 * @return {number}
 */
proto.services.SelfBalanceResponse.prototype.getChannelRemoteMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.services.SelfBalanceResponse.prototype.setChannelRemoteMsat = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 pending_open_local_msat = 5;
 * @return {number}
 */
proto.services.SelfBalanceResponse.prototype.getPendingOpenLocalMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.services.SelfBalanceResponse.prototype.setPendingOpenLocalMsat = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 pending_open_remote_msat = 6;
 * @return {number}
 */
proto.services.SelfBalanceResponse.prototype.getPendingOpenRemoteMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.services.SelfBalanceResponse.prototype.setPendingOpenRemoteMsat = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 unsettled_local_msat = 7;
 * @return {number}
 */
proto.services.SelfBalanceResponse.prototype.getUnsettledLocalMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.services.SelfBalanceResponse.prototype.setUnsettledLocalMsat = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 unsettled_remote_msat = 8;
 * @return {number}
 */
proto.services.SelfBalanceResponse.prototype.getUnsettledRemoteMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.services.SelfBalanceResponse.prototype.setUnsettledRemoteMsat = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetNodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetNodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.GetNodesRequest.displayName = 'proto.services.GetNodesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.GetNodesRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.GetNodesRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetNodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.GetNodesRequest.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetNodesRequest}
 */
proto.services.GetNodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetNodesRequest;
  return proto.services.GetNodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetNodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetNodesRequest}
 */
proto.services.GetNodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetNodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetNodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetNodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetNodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SearchNodeByAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SearchNodeByAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SearchNodeByAddressRequest.displayName = 'proto.services.SearchNodeByAddressRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SearchNodeByAddressRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SearchNodeByAddressRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SearchNodeByAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SearchNodeByAddressRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      address: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SearchNodeByAddressRequest}
 */
proto.services.SearchNodeByAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SearchNodeByAddressRequest;
  return proto.services.SearchNodeByAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SearchNodeByAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SearchNodeByAddressRequest}
 */
proto.services.SearchNodeByAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SearchNodeByAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SearchNodeByAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SearchNodeByAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SearchNodeByAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.services.SearchNodeByAddressRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.SearchNodeByAddressRequest.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SearchNodeByAliasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SearchNodeByAliasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SearchNodeByAliasRequest.displayName = 'proto.services.SearchNodeByAliasRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SearchNodeByAliasRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SearchNodeByAliasRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SearchNodeByAliasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SearchNodeByAliasRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      alias: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SearchNodeByAliasRequest}
 */
proto.services.SearchNodeByAliasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SearchNodeByAliasRequest;
  return proto.services.SearchNodeByAliasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SearchNodeByAliasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SearchNodeByAliasRequest}
 */
proto.services.SearchNodeByAliasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SearchNodeByAliasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SearchNodeByAliasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SearchNodeByAliasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SearchNodeByAliasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string alias = 1;
 * @return {string}
 */
proto.services.SearchNodeByAliasRequest.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.SearchNodeByAliasRequest.prototype.setAlias = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.NodeInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.NodeInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.services.NodeInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.NodeInfoResponse.displayName = 'proto.services.NodeInfoResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.NodeInfoResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.NodeInfoResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.NodeInfoResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.NodeInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.NodeInfoResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      nodesList: jspb.Message.toObjectList(msg.getNodesList(),
        proto.services.NodeInfo.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.NodeInfoResponse}
 */
proto.services.NodeInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.NodeInfoResponse;
  return proto.services.NodeInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.NodeInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.NodeInfoResponse}
 */
proto.services.NodeInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.NodeInfo;
      reader.readMessage(value,proto.services.NodeInfo.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.NodeInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.NodeInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.NodeInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.NodeInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.NodeInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeInfo nodes = 1;
 * @return {!Array<!proto.services.NodeInfo>}
 */
proto.services.NodeInfoResponse.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.services.NodeInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.NodeInfo, 1));
};


/** @param {!Array<!proto.services.NodeInfo>} value */
proto.services.NodeInfoResponse.prototype.setNodesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.NodeInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.NodeInfo}
 */
proto.services.NodeInfoResponse.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.NodeInfo, opt_index);
};


proto.services.NodeInfoResponse.prototype.clearNodesList = function() {
  this.setNodesList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ConnectNodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ConnectNodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.ConnectNodeRequest.displayName = 'proto.services.ConnectNodeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.ConnectNodeRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.ConnectNodeRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ConnectNodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.ConnectNodeRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      address: jspb.Message.getFieldWithDefault(msg, 1, ""),
      hostport: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ConnectNodeRequest}
 */
proto.services.ConnectNodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ConnectNodeRequest;
  return proto.services.ConnectNodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ConnectNodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ConnectNodeRequest}
 */
proto.services.ConnectNodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ConnectNodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ConnectNodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ConnectNodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ConnectNodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHostport();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.services.ConnectNodeRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.ConnectNodeRequest.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hostport = 2;
 * @return {string}
 */
proto.services.ConnectNodeRequest.prototype.getHostport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.services.ConnectNodeRequest.prototype.setHostport = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ConnectNodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ConnectNodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.ConnectNodeResponse.displayName = 'proto.services.ConnectNodeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.ConnectNodeResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.ConnectNodeResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ConnectNodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.ConnectNodeResponse.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ConnectNodeResponse}
 */
proto.services.ConnectNodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ConnectNodeResponse;
  return proto.services.ConnectNodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ConnectNodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ConnectNodeResponse}
 */
proto.services.ConnectNodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ConnectNodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ConnectNodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ConnectNodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ConnectNodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.OpenChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.OpenChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.OpenChannelRequest.displayName = 'proto.services.OpenChannelRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.OpenChannelRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.OpenChannelRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.OpenChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.OpenChannelRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      address: jspb.Message.getFieldWithDefault(msg, 1, ""),
      amtMsat: jspb.Message.getFieldWithDefault(msg, 2, 0),
      pushAmtMsat: jspb.Message.getFieldWithDefault(msg, 3, 0),
      minInputConfs: jspb.Message.getFieldWithDefault(msg, 4, 0),
      targetConfirmationBlock: jspb.Message.getFieldWithDefault(msg, 5, 0),
      satPerVbyte: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.OpenChannelRequest}
 */
proto.services.OpenChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.OpenChannelRequest;
  return proto.services.OpenChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.OpenChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.OpenChannelRequest}
 */
proto.services.OpenChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmtMsat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPushAmtMsat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinInputConfs(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTargetConfirmationBlock(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSatPerVbyte(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.OpenChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.OpenChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.OpenChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.OpenChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmtMsat();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPushAmtMsat();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getMinInputConfs();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTargetConfirmationBlock();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getSatPerVbyte();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.services.OpenChannelRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.OpenChannelRequest.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 amt_msat = 2;
 * @return {number}
 */
proto.services.OpenChannelRequest.prototype.getAmtMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.OpenChannelRequest.prototype.setAmtMsat = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 push_amt_msat = 3;
 * @return {number}
 */
proto.services.OpenChannelRequest.prototype.getPushAmtMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.services.OpenChannelRequest.prototype.setPushAmtMsat = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 min_input_confs = 4;
 * @return {number}
 */
proto.services.OpenChannelRequest.prototype.getMinInputConfs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.services.OpenChannelRequest.prototype.setMinInputConfs = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 target_confirmation_block = 5;
 * @return {number}
 */
proto.services.OpenChannelRequest.prototype.getTargetConfirmationBlock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.services.OpenChannelRequest.prototype.setTargetConfirmationBlock = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 sat_per_vbyte = 6;
 * @return {number}
 */
proto.services.OpenChannelRequest.prototype.getSatPerVbyte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.services.OpenChannelRequest.prototype.setSatPerVbyte = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.OpenChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.OpenChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.OpenChannelResponse.displayName = 'proto.services.OpenChannelResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.OpenChannelResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.OpenChannelResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.OpenChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.OpenChannelResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      fundingTxid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      outputIndex: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.OpenChannelResponse}
 */
proto.services.OpenChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.OpenChannelResponse;
  return proto.services.OpenChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.OpenChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.OpenChannelResponse}
 */
proto.services.OpenChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFundingTxid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOutputIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.OpenChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.OpenChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.OpenChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.OpenChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFundingTxid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutputIndex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string funding_txid = 1;
 * @return {string}
 */
proto.services.OpenChannelResponse.prototype.getFundingTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.OpenChannelResponse.prototype.setFundingTxid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 output_index = 2;
 * @return {number}
 */
proto.services.OpenChannelResponse.prototype.getOutputIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.OpenChannelResponse.prototype.setOutputIndex = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.ContactInfo.displayName = 'proto.services.ContactInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.ContactInfo.prototype.toObject = function(opt_includeInstance) {
    return proto.services.ContactInfo.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.ContactInfo.toObject = function(includeInstance, msg) {
    var f, obj = {
      node: (f = msg.getNode()) && proto.services.NodeInfo.toObject(includeInstance, f),
      id: jspb.Message.getFieldWithDefault(msg, 2, 0),
      displayName: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ContactInfo}
 */
proto.services.ContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ContactInfo;
  return proto.services.ContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ContactInfo}
 */
proto.services.ContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.NodeInfo;
      reader.readMessage(value,proto.services.NodeInfo.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.NodeInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional NodeInfo node = 1;
 * @return {?proto.services.NodeInfo}
 */
proto.services.ContactInfo.prototype.getNode = function() {
  return /** @type{?proto.services.NodeInfo} */ (
    jspb.Message.getWrapperField(this, proto.services.NodeInfo, 1));
};


/** @param {?proto.services.NodeInfo|undefined} value */
proto.services.ContactInfo.prototype.setNode = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.ContactInfo.prototype.clearNode = function() {
  this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.ContactInfo.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 id = 2;
 * @return {number}
 */
proto.services.ContactInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.ContactInfo.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.services.ContactInfo.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.services.ContactInfo.prototype.setDisplayName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetContactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetContactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.GetContactsRequest.displayName = 'proto.services.GetContactsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.GetContactsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.GetContactsRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetContactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.GetContactsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetContactsRequest}
 */
proto.services.GetContactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetContactsRequest;
  return proto.services.GetContactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetContactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetContactsRequest}
 */
proto.services.GetContactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetContactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetContactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetContactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetContactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetContactsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GetContactsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.GetContactsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.GetContactsResponse.displayName = 'proto.services.GetContactsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GetContactsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.GetContactsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.GetContactsResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetContactsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.GetContactsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      contactsList: jspb.Message.toObjectList(msg.getContactsList(),
        proto.services.ContactInfo.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetContactsResponse}
 */
proto.services.GetContactsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetContactsResponse;
  return proto.services.GetContactsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetContactsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetContactsResponse}
 */
proto.services.GetContactsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.ContactInfo;
      reader.readMessage(value,proto.services.ContactInfo.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetContactsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetContactsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetContactsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetContactsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.ContactInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ContactInfo contacts = 1;
 * @return {!Array<!proto.services.ContactInfo>}
 */
proto.services.GetContactsResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.services.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.ContactInfo, 1));
};


/** @param {!Array<!proto.services.ContactInfo>} value */
proto.services.GetContactsResponse.prototype.setContactsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.ContactInfo}
 */
proto.services.GetContactsResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.ContactInfo, opt_index);
};


proto.services.GetContactsResponse.prototype.clearContactsList = function() {
  this.setContactsList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.AddContactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.AddContactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.AddContactRequest.displayName = 'proto.services.AddContactRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.AddContactRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.AddContactRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.AddContactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.AddContactRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      contact: (f = msg.getContact()) && proto.services.ContactInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.AddContactRequest}
 */
proto.services.AddContactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.AddContactRequest;
  return proto.services.AddContactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.AddContactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.AddContactRequest}
 */
proto.services.AddContactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.ContactInfo;
      reader.readMessage(value,proto.services.ContactInfo.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.AddContactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.AddContactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.AddContactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.AddContactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.ContactInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContactInfo contact = 1;
 * @return {?proto.services.ContactInfo}
 */
proto.services.AddContactRequest.prototype.getContact = function() {
  return /** @type{?proto.services.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.services.ContactInfo, 1));
};


/** @param {?proto.services.ContactInfo|undefined} value */
proto.services.AddContactRequest.prototype.setContact = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.AddContactRequest.prototype.clearContact = function() {
  this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.AddContactRequest.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.AddContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.AddContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.AddContactResponse.displayName = 'proto.services.AddContactResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.AddContactResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.AddContactResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.AddContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.AddContactResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      contact: (f = msg.getContact()) && proto.services.ContactInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.AddContactResponse}
 */
proto.services.AddContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.AddContactResponse;
  return proto.services.AddContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.AddContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.AddContactResponse}
 */
proto.services.AddContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.ContactInfo;
      reader.readMessage(value,proto.services.ContactInfo.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.AddContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.AddContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.AddContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.AddContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.ContactInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContactInfo contact = 1;
 * @return {?proto.services.ContactInfo}
 */
proto.services.AddContactResponse.prototype.getContact = function() {
  return /** @type{?proto.services.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.services.ContactInfo, 1));
};


/** @param {?proto.services.ContactInfo|undefined} value */
proto.services.AddContactResponse.prototype.setContact = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.AddContactResponse.prototype.clearContact = function() {
  this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.AddContactResponse.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.RemoveContactByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.RemoveContactByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.RemoveContactByIDRequest.displayName = 'proto.services.RemoveContactByIDRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.RemoveContactByIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.RemoveContactByIDRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.RemoveContactByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.RemoveContactByIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.RemoveContactByIDRequest}
 */
proto.services.RemoveContactByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.RemoveContactByIDRequest;
  return proto.services.RemoveContactByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.RemoveContactByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.RemoveContactByIDRequest}
 */
proto.services.RemoveContactByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.RemoveContactByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.RemoveContactByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.RemoveContactByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.RemoveContactByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.services.RemoveContactByIDRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.RemoveContactByIDRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.RemoveContactByAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.RemoveContactByAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.RemoveContactByAddressRequest.displayName = 'proto.services.RemoveContactByAddressRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.RemoveContactByAddressRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.RemoveContactByAddressRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.RemoveContactByAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.RemoveContactByAddressRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      address: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.RemoveContactByAddressRequest}
 */
proto.services.RemoveContactByAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.RemoveContactByAddressRequest;
  return proto.services.RemoveContactByAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.RemoveContactByAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.RemoveContactByAddressRequest}
 */
proto.services.RemoveContactByAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.RemoveContactByAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.RemoveContactByAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.RemoveContactByAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.RemoveContactByAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.services.RemoveContactByAddressRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.RemoveContactByAddressRequest.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.RemoveContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.RemoveContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.RemoveContactResponse.displayName = 'proto.services.RemoveContactResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.RemoveContactResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.RemoveContactResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.RemoveContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.RemoveContactResponse.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.RemoveContactResponse}
 */
proto.services.RemoveContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.RemoveContactResponse;
  return proto.services.RemoveContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.RemoveContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.RemoveContactResponse}
 */
proto.services.RemoveContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.RemoveContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.RemoveContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.RemoveContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.RemoveContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.Message.repeatedFields_, null);
};
goog.inherits(proto.services.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.Message.displayName = 'proto.services.Message';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.Message.repeatedFields_ = [10];


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.Message.prototype.toObject = function(opt_includeInstance) {
    return proto.services.Message.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.Message.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      discussionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      sender: jspb.Message.getFieldWithDefault(msg, 3, ""),
      receiver: jspb.Message.getFieldWithDefault(msg, 4, ""),
      senderVerified: jspb.Message.getFieldWithDefault(msg, 11, false),
      payload: jspb.Message.getFieldWithDefault(msg, 5, ""),
      amtMsat: jspb.Message.getFieldWithDefault(msg, 6, 0),
      totalFeesMsat: jspb.Message.getFieldWithDefault(msg, 7, 0),
      sentTimestamp: (f = msg.getSentTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      receivedTimestamp: (f = msg.getReceivedTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      paymentRoutesList: jspb.Message.toObjectList(msg.getPaymentRoutesList(),
        proto.services.PaymentRoute.toObject, includeInstance),
      preimage: jspb.Message.getFieldWithDefault(msg, 12, ""),
      payReq: jspb.Message.getFieldWithDefault(msg, 13, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.Message}
 */
proto.services.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.Message;
  return proto.services.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.Message}
 */
proto.services.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiscussionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSender(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiver(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSenderVerified(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmtMsat(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalFeesMsat(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSentTimestamp(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReceivedTimestamp(value);
      break;
    case 10:
      var value = new proto.services.PaymentRoute;
      reader.readMessage(value,proto.services.PaymentRoute.deserializeBinaryFromReader);
      msg.addPaymentRoutes(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreimage(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayReq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getDiscussionId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReceiver();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSenderVerified();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmtMsat();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalFeesMsat();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSentTimestamp();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReceivedTimestamp();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.services.PaymentRoute.serializeBinaryToWriter
    );
  }
  f = message.getPreimage();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPayReq();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.services.Message.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.Message.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 discussion_id = 2;
 * @return {number}
 */
proto.services.Message.prototype.getDiscussionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.Message.prototype.setDiscussionId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sender = 3;
 * @return {string}
 */
proto.services.Message.prototype.getSender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.services.Message.prototype.setSender = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string receiver = 4;
 * @return {string}
 */
proto.services.Message.prototype.getReceiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.services.Message.prototype.setReceiver = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool sender_verified = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.services.Message.prototype.getSenderVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.services.Message.prototype.setSenderVerified = function(value) {
  jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string payload = 5;
 * @return {string}
 */
proto.services.Message.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.services.Message.prototype.setPayload = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 amt_msat = 6;
 * @return {number}
 */
proto.services.Message.prototype.getAmtMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.services.Message.prototype.setAmtMsat = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 total_fees_msat = 7;
 * @return {number}
 */
proto.services.Message.prototype.getTotalFeesMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.services.Message.prototype.setTotalFeesMsat = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp sent_timestamp = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.Message.prototype.getSentTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.services.Message.prototype.setSentTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.services.Message.prototype.clearSentTimestamp = function() {
  this.setSentTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.Message.prototype.hasSentTimestamp = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp received_timestamp = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.Message.prototype.getReceivedTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.services.Message.prototype.setReceivedTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 9, value);
};


proto.services.Message.prototype.clearReceivedTimestamp = function() {
  this.setReceivedTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.Message.prototype.hasReceivedTimestamp = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated PaymentRoute payment_routes = 10;
 * @return {!Array<!proto.services.PaymentRoute>}
 */
proto.services.Message.prototype.getPaymentRoutesList = function() {
  return /** @type{!Array<!proto.services.PaymentRoute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.PaymentRoute, 10));
};


/** @param {!Array<!proto.services.PaymentRoute>} value */
proto.services.Message.prototype.setPaymentRoutesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.services.PaymentRoute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.PaymentRoute}
 */
proto.services.Message.prototype.addPaymentRoutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.services.PaymentRoute, opt_index);
};


proto.services.Message.prototype.clearPaymentRoutesList = function() {
  this.setPaymentRoutesList([]);
};


/**
 * optional string preimage = 12;
 * @return {string}
 */
proto.services.Message.prototype.getPreimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.services.Message.prototype.setPreimage = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string pay_req = 13;
 * @return {string}
 */
proto.services.Message.prototype.getPayReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.services.Message.prototype.setPayReq = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.PaymentRoute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.PaymentRoute.repeatedFields_, null);
};
goog.inherits(proto.services.PaymentRoute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.PaymentRoute.displayName = 'proto.services.PaymentRoute';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.PaymentRoute.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.PaymentRoute.prototype.toObject = function(opt_includeInstance) {
    return proto.services.PaymentRoute.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.PaymentRoute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.PaymentRoute.toObject = function(includeInstance, msg) {
    var f, obj = {
      hopsList: jspb.Message.toObjectList(msg.getHopsList(),
        proto.services.PaymentHop.toObject, includeInstance),
      totalTimelock: jspb.Message.getFieldWithDefault(msg, 2, 0),
      routeAmtMsat: jspb.Message.getFieldWithDefault(msg, 3, 0),
      routeFeesMsat: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.PaymentRoute}
 */
proto.services.PaymentRoute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.PaymentRoute;
  return proto.services.PaymentRoute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.PaymentRoute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.PaymentRoute}
 */
proto.services.PaymentRoute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.PaymentHop;
      reader.readMessage(value,proto.services.PaymentHop.deserializeBinaryFromReader);
      msg.addHops(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalTimelock(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRouteAmtMsat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRouteFeesMsat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.PaymentRoute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.PaymentRoute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.PaymentRoute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.PaymentRoute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.PaymentHop.serializeBinaryToWriter
    );
  }
  f = message.getTotalTimelock();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRouteAmtMsat();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRouteFeesMsat();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated PaymentHop hops = 1;
 * @return {!Array<!proto.services.PaymentHop>}
 */
proto.services.PaymentRoute.prototype.getHopsList = function() {
  return /** @type{!Array<!proto.services.PaymentHop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.PaymentHop, 1));
};


/** @param {!Array<!proto.services.PaymentHop>} value */
proto.services.PaymentRoute.prototype.setHopsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.PaymentHop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.PaymentHop}
 */
proto.services.PaymentRoute.prototype.addHops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.PaymentHop, opt_index);
};


proto.services.PaymentRoute.prototype.clearHopsList = function() {
  this.setHopsList([]);
};


/**
 * optional uint32 total_timelock = 2;
 * @return {number}
 */
proto.services.PaymentRoute.prototype.getTotalTimelock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.PaymentRoute.prototype.setTotalTimelock = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 route_amt_msat = 3;
 * @return {number}
 */
proto.services.PaymentRoute.prototype.getRouteAmtMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.services.PaymentRoute.prototype.setRouteAmtMsat = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 route_fees_msat = 4;
 * @return {number}
 */
proto.services.PaymentRoute.prototype.getRouteFeesMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.services.PaymentRoute.prototype.setRouteFeesMsat = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.PaymentHop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.PaymentHop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.PaymentHop.displayName = 'proto.services.PaymentHop';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.PaymentHop.prototype.toObject = function(opt_includeInstance) {
    return proto.services.PaymentHop.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.PaymentHop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.PaymentHop.toObject = function(includeInstance, msg) {
    var f, obj = {
      chanId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      hopAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
      amtToForwardMsat: jspb.Message.getFieldWithDefault(msg, 3, 0),
      feeMsat: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.PaymentHop}
 */
proto.services.PaymentHop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.PaymentHop;
  return proto.services.PaymentHop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.PaymentHop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.PaymentHop}
 */
proto.services.PaymentHop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHopAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmtToForwardMsat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeeMsat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.PaymentHop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.PaymentHop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.PaymentHop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.PaymentHop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getHopAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmtToForwardMsat();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFeeMsat();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional uint64 chan_id = 1;
 * @return {number}
 */
proto.services.PaymentHop.prototype.getChanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.PaymentHop.prototype.setChanId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hop_address = 2;
 * @return {string}
 */
proto.services.PaymentHop.prototype.getHopAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.services.PaymentHop.prototype.setHopAddress = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 amt_to_forward_msat = 3;
 * @return {number}
 */
proto.services.PaymentHop.prototype.getAmtToForwardMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.services.PaymentHop.prototype.setAmtToForwardMsat = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 fee_msat = 4;
 * @return {number}
 */
proto.services.PaymentHop.prototype.getFeeMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.services.PaymentHop.prototype.setFeeMsat = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.MessageOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.MessageOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.MessageOptions.displayName = 'proto.services.MessageOptions';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.MessageOptions.prototype.toObject = function(opt_includeInstance) {
    return proto.services.MessageOptions.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.MessageOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.MessageOptions.toObject = function(includeInstance, msg) {
    var f, obj = {
      feeLimitMsat: jspb.Message.getFieldWithDefault(msg, 1, 0),
      anonymous: jspb.Message.getFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.MessageOptions}
 */
proto.services.MessageOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.MessageOptions;
  return proto.services.MessageOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.MessageOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.MessageOptions}
 */
proto.services.MessageOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeeLimitMsat(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnonymous(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.MessageOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.MessageOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.MessageOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.MessageOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeLimitMsat();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnonymous();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 fee_limit_msat = 1;
 * @return {number}
 */
proto.services.MessageOptions.prototype.getFeeLimitMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.MessageOptions.prototype.setFeeLimitMsat = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool anonymous = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.services.MessageOptions.prototype.getAnonymous = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.services.MessageOptions.prototype.setAnonymous = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.EstimateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.EstimateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.EstimateMessageRequest.displayName = 'proto.services.EstimateMessageRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.EstimateMessageRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.EstimateMessageRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.EstimateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.EstimateMessageRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      discussionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      payload: jspb.Message.getFieldWithDefault(msg, 2, ""),
      amtMsat: jspb.Message.getFieldWithDefault(msg, 3, 0),
      options: (f = msg.getOptions()) && proto.services.MessageOptions.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.EstimateMessageRequest}
 */
proto.services.EstimateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.EstimateMessageRequest;
  return proto.services.EstimateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.EstimateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.EstimateMessageRequest}
 */
proto.services.EstimateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiscussionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmtMsat(value);
      break;
    case 4:
      var value = new proto.services.MessageOptions;
      reader.readMessage(value,proto.services.MessageOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.EstimateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.EstimateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.EstimateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.EstimateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussionId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmtMsat();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.services.MessageOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 discussion_id = 1;
 * @return {number}
 */
proto.services.EstimateMessageRequest.prototype.getDiscussionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.EstimateMessageRequest.prototype.setDiscussionId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string payload = 2;
 * @return {string}
 */
proto.services.EstimateMessageRequest.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.services.EstimateMessageRequest.prototype.setPayload = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 amt_msat = 3;
 * @return {number}
 */
proto.services.EstimateMessageRequest.prototype.getAmtMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.services.EstimateMessageRequest.prototype.setAmtMsat = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional MessageOptions options = 4;
 * @return {?proto.services.MessageOptions}
 */
proto.services.EstimateMessageRequest.prototype.getOptions = function() {
  return /** @type{?proto.services.MessageOptions} */ (
    jspb.Message.getWrapperField(this, proto.services.MessageOptions, 4));
};


/** @param {?proto.services.MessageOptions|undefined} value */
proto.services.EstimateMessageRequest.prototype.setOptions = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.services.EstimateMessageRequest.prototype.clearOptions = function() {
  this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.EstimateMessageRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.EstimateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.EstimateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.EstimateMessageResponse.displayName = 'proto.services.EstimateMessageResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.EstimateMessageResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.EstimateMessageResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.EstimateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.EstimateMessageResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      successProb: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
      message: (f = msg.getMessage()) && proto.services.Message.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.EstimateMessageResponse}
 */
proto.services.EstimateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.EstimateMessageResponse;
  return proto.services.EstimateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.EstimateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.EstimateMessageResponse}
 */
proto.services.EstimateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSuccessProb(value);
      break;
    case 2:
      var value = new proto.services.Message;
      reader.readMessage(value,proto.services.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.EstimateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.EstimateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.EstimateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.EstimateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccessProb();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional double success_prob = 1;
 * @return {number}
 */
proto.services.EstimateMessageResponse.prototype.getSuccessProb = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.services.EstimateMessageResponse.prototype.setSuccessProb = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Message message = 2;
 * @return {?proto.services.Message}
 */
proto.services.EstimateMessageResponse.prototype.getMessage = function() {
  return /** @type{?proto.services.Message} */ (
    jspb.Message.getWrapperField(this, proto.services.Message, 2));
};


/** @param {?proto.services.Message|undefined} value */
proto.services.EstimateMessageResponse.prototype.setMessage = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.services.EstimateMessageResponse.prototype.clearMessage = function() {
  this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.EstimateMessageResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SendMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SendMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SendMessageRequest.displayName = 'proto.services.SendMessageRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SendMessageRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SendMessageRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SendMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SendMessageRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      discussionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      payload: jspb.Message.getFieldWithDefault(msg, 2, ""),
      amtMsat: jspb.Message.getFieldWithDefault(msg, 3, 0),
      payReq: jspb.Message.getFieldWithDefault(msg, 5, ""),
      options: (f = msg.getOptions()) && proto.services.MessageOptions.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SendMessageRequest}
 */
proto.services.SendMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SendMessageRequest;
  return proto.services.SendMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SendMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SendMessageRequest}
 */
proto.services.SendMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiscussionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmtMsat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayReq(value);
      break;
    case 4:
      var value = new proto.services.MessageOptions;
      reader.readMessage(value,proto.services.MessageOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SendMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SendMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SendMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SendMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussionId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmtMsat();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPayReq();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.services.MessageOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 discussion_id = 1;
 * @return {number}
 */
proto.services.SendMessageRequest.prototype.getDiscussionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.SendMessageRequest.prototype.setDiscussionId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string payload = 2;
 * @return {string}
 */
proto.services.SendMessageRequest.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.services.SendMessageRequest.prototype.setPayload = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 amt_msat = 3;
 * @return {number}
 */
proto.services.SendMessageRequest.prototype.getAmtMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.services.SendMessageRequest.prototype.setAmtMsat = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string pay_req = 5;
 * @return {string}
 */
proto.services.SendMessageRequest.prototype.getPayReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.services.SendMessageRequest.prototype.setPayReq = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional MessageOptions options = 4;
 * @return {?proto.services.MessageOptions}
 */
proto.services.SendMessageRequest.prototype.getOptions = function() {
  return /** @type{?proto.services.MessageOptions} */ (
    jspb.Message.getWrapperField(this, proto.services.MessageOptions, 4));
};


/** @param {?proto.services.MessageOptions|undefined} value */
proto.services.SendMessageRequest.prototype.setOptions = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.services.SendMessageRequest.prototype.clearOptions = function() {
  this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.SendMessageRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SendMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SendMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SendMessageResponse.displayName = 'proto.services.SendMessageResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SendMessageResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SendMessageResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SendMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SendMessageResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      sentMessage: (f = msg.getSentMessage()) && proto.services.Message.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SendMessageResponse}
 */
proto.services.SendMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SendMessageResponse;
  return proto.services.SendMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SendMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SendMessageResponse}
 */
proto.services.SendMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.Message;
      reader.readMessage(value,proto.services.Message.deserializeBinaryFromReader);
      msg.setSentMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SendMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SendMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SendMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SendMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSentMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional Message sent_message = 1;
 * @return {?proto.services.Message}
 */
proto.services.SendMessageResponse.prototype.getSentMessage = function() {
  return /** @type{?proto.services.Message} */ (
    jspb.Message.getWrapperField(this, proto.services.Message, 1));
};


/** @param {?proto.services.Message|undefined} value */
proto.services.SendMessageResponse.prototype.setSentMessage = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.SendMessageResponse.prototype.clearSentMessage = function() {
  this.setSentMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.SendMessageResponse.prototype.hasSentMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SubscribeMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SubscribeMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SubscribeMessageRequest.displayName = 'proto.services.SubscribeMessageRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SubscribeMessageRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SubscribeMessageRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SubscribeMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SubscribeMessageRequest.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SubscribeMessageRequest}
 */
proto.services.SubscribeMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SubscribeMessageRequest;
  return proto.services.SubscribeMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SubscribeMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SubscribeMessageRequest}
 */
proto.services.SubscribeMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SubscribeMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SubscribeMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SubscribeMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SubscribeMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SubscribeMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SubscribeMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.SubscribeMessageResponse.displayName = 'proto.services.SubscribeMessageResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.SubscribeMessageResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.SubscribeMessageResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SubscribeMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.SubscribeMessageResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      receivedMessage: (f = msg.getReceivedMessage()) && proto.services.Message.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SubscribeMessageResponse}
 */
proto.services.SubscribeMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SubscribeMessageResponse;
  return proto.services.SubscribeMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SubscribeMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SubscribeMessageResponse}
 */
proto.services.SubscribeMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.Message;
      reader.readMessage(value,proto.services.Message.deserializeBinaryFromReader);
      msg.setReceivedMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SubscribeMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SubscribeMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SubscribeMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SubscribeMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceivedMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional Message received_message = 1;
 * @return {?proto.services.Message}
 */
proto.services.SubscribeMessageResponse.prototype.getReceivedMessage = function() {
  return /** @type{?proto.services.Message} */ (
    jspb.Message.getWrapperField(this, proto.services.Message, 1));
};


/** @param {?proto.services.Message|undefined} value */
proto.services.SubscribeMessageResponse.prototype.setReceivedMessage = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.SubscribeMessageResponse.prototype.clearReceivedMessage = function() {
  this.setReceivedMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.SubscribeMessageResponse.prototype.hasReceivedMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DiscussionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.DiscussionInfo.repeatedFields_, null);
};
goog.inherits(proto.services.DiscussionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.DiscussionInfo.displayName = 'proto.services.DiscussionInfo';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.DiscussionInfo.repeatedFields_ = [2];


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.DiscussionInfo.prototype.toObject = function(opt_includeInstance) {
    return proto.services.DiscussionInfo.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DiscussionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.DiscussionInfo.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      participantsList: jspb.Message.getRepeatedField(msg, 2),
      options: (f = msg.getOptions()) && proto.services.DiscussionOptions.toObject(includeInstance, f),
      lastReadMsgId: jspb.Message.getFieldWithDefault(msg, 4, 0),
      lastMsgId: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DiscussionInfo}
 */
proto.services.DiscussionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DiscussionInfo;
  return proto.services.DiscussionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DiscussionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DiscussionInfo}
 */
proto.services.DiscussionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addParticipants(value);
      break;
    case 3:
      var value = new proto.services.DiscussionOptions;
      reader.readMessage(value,proto.services.DiscussionOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastReadMsgId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastMsgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DiscussionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DiscussionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DiscussionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DiscussionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.services.DiscussionOptions.serializeBinaryToWriter
    );
  }
  f = message.getLastReadMsgId();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getLastMsgId();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.services.DiscussionInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.DiscussionInfo.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string participants = 2;
 * @return {!Array<string>}
 */
proto.services.DiscussionInfo.prototype.getParticipantsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.services.DiscussionInfo.prototype.setParticipantsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.services.DiscussionInfo.prototype.addParticipants = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.services.DiscussionInfo.prototype.clearParticipantsList = function() {
  this.setParticipantsList([]);
};


/**
 * optional DiscussionOptions options = 3;
 * @return {?proto.services.DiscussionOptions}
 */
proto.services.DiscussionInfo.prototype.getOptions = function() {
  return /** @type{?proto.services.DiscussionOptions} */ (
    jspb.Message.getWrapperField(this, proto.services.DiscussionOptions, 3));
};


/** @param {?proto.services.DiscussionOptions|undefined} value */
proto.services.DiscussionInfo.prototype.setOptions = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.services.DiscussionInfo.prototype.clearOptions = function() {
  this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.DiscussionInfo.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 last_read_msg_id = 4;
 * @return {number}
 */
proto.services.DiscussionInfo.prototype.getLastReadMsgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.services.DiscussionInfo.prototype.setLastReadMsgId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 last_msg_id = 5;
 * @return {number}
 */
proto.services.DiscussionInfo.prototype.getLastMsgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.services.DiscussionInfo.prototype.setLastMsgId = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DiscussionOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DiscussionOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.DiscussionOptions.displayName = 'proto.services.DiscussionOptions';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.DiscussionOptions.prototype.toObject = function(opt_includeInstance) {
    return proto.services.DiscussionOptions.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DiscussionOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.DiscussionOptions.toObject = function(includeInstance, msg) {
    var f, obj = {
      feeLimitMsat: jspb.Message.getFieldWithDefault(msg, 1, 0),
      anonymous: jspb.Message.getFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DiscussionOptions}
 */
proto.services.DiscussionOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DiscussionOptions;
  return proto.services.DiscussionOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DiscussionOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DiscussionOptions}
 */
proto.services.DiscussionOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeeLimitMsat(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnonymous(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DiscussionOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DiscussionOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DiscussionOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DiscussionOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeLimitMsat();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnonymous();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 fee_limit_msat = 1;
 * @return {number}
 */
proto.services.DiscussionOptions.prototype.getFeeLimitMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.DiscussionOptions.prototype.setFeeLimitMsat = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool anonymous = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.services.DiscussionOptions.prototype.getAnonymous = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.services.DiscussionOptions.prototype.setAnonymous = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetDiscussionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetDiscussionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.GetDiscussionsRequest.displayName = 'proto.services.GetDiscussionsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.GetDiscussionsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.GetDiscussionsRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetDiscussionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.GetDiscussionsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetDiscussionsRequest}
 */
proto.services.GetDiscussionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetDiscussionsRequest;
  return proto.services.GetDiscussionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetDiscussionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetDiscussionsRequest}
 */
proto.services.GetDiscussionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetDiscussionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetDiscussionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetDiscussionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetDiscussionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetDiscussionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetDiscussionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.GetDiscussionsResponse.displayName = 'proto.services.GetDiscussionsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.GetDiscussionsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.GetDiscussionsResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetDiscussionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.GetDiscussionsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      discussion: (f = msg.getDiscussion()) && proto.services.DiscussionInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetDiscussionsResponse}
 */
proto.services.GetDiscussionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetDiscussionsResponse;
  return proto.services.GetDiscussionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetDiscussionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetDiscussionsResponse}
 */
proto.services.GetDiscussionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.DiscussionInfo;
      reader.readMessage(value,proto.services.DiscussionInfo.deserializeBinaryFromReader);
      msg.setDiscussion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetDiscussionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetDiscussionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetDiscussionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetDiscussionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.DiscussionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional DiscussionInfo discussion = 1;
 * @return {?proto.services.DiscussionInfo}
 */
proto.services.GetDiscussionsResponse.prototype.getDiscussion = function() {
  return /** @type{?proto.services.DiscussionInfo} */ (
    jspb.Message.getWrapperField(this, proto.services.DiscussionInfo, 1));
};


/** @param {?proto.services.DiscussionInfo|undefined} value */
proto.services.GetDiscussionsResponse.prototype.setDiscussion = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.GetDiscussionsResponse.prototype.clearDiscussion = function() {
  this.setDiscussion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.GetDiscussionsResponse.prototype.hasDiscussion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetDiscussionHistoryByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetDiscussionHistoryByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.GetDiscussionHistoryByIDRequest.displayName = 'proto.services.GetDiscussionHistoryByIDRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.GetDiscussionHistoryByIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.GetDiscussionHistoryByIDRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetDiscussionHistoryByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.GetDiscussionHistoryByIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      pageOptions: (f = msg.getPageOptions()) && proto.services.KeySetPageOptions.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetDiscussionHistoryByIDRequest}
 */
proto.services.GetDiscussionHistoryByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetDiscussionHistoryByIDRequest;
  return proto.services.GetDiscussionHistoryByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetDiscussionHistoryByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetDiscussionHistoryByIDRequest}
 */
proto.services.GetDiscussionHistoryByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.services.KeySetPageOptions;
      reader.readMessage(value,proto.services.KeySetPageOptions.deserializeBinaryFromReader);
      msg.setPageOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetDiscussionHistoryByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetDiscussionHistoryByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetDiscussionHistoryByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetDiscussionHistoryByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPageOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.KeySetPageOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.services.GetDiscussionHistoryByIDRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.GetDiscussionHistoryByIDRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional KeySetPageOptions page_options = 2;
 * @return {?proto.services.KeySetPageOptions}
 */
proto.services.GetDiscussionHistoryByIDRequest.prototype.getPageOptions = function() {
  return /** @type{?proto.services.KeySetPageOptions} */ (
    jspb.Message.getWrapperField(this, proto.services.KeySetPageOptions, 2));
};


/** @param {?proto.services.KeySetPageOptions|undefined} value */
proto.services.GetDiscussionHistoryByIDRequest.prototype.setPageOptions = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.services.GetDiscussionHistoryByIDRequest.prototype.clearPageOptions = function() {
  this.setPageOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.GetDiscussionHistoryByIDRequest.prototype.hasPageOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetDiscussionHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetDiscussionHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.GetDiscussionHistoryResponse.displayName = 'proto.services.GetDiscussionHistoryResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.GetDiscussionHistoryResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.GetDiscussionHistoryResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetDiscussionHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.GetDiscussionHistoryResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      message: (f = msg.getMessage()) && proto.services.Message.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetDiscussionHistoryResponse}
 */
proto.services.GetDiscussionHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetDiscussionHistoryResponse;
  return proto.services.GetDiscussionHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetDiscussionHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetDiscussionHistoryResponse}
 */
proto.services.GetDiscussionHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.Message;
      reader.readMessage(value,proto.services.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetDiscussionHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetDiscussionHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetDiscussionHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetDiscussionHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional Message message = 1;
 * @return {?proto.services.Message}
 */
proto.services.GetDiscussionHistoryResponse.prototype.getMessage = function() {
  return /** @type{?proto.services.Message} */ (
    jspb.Message.getWrapperField(this, proto.services.Message, 1));
};


/** @param {?proto.services.Message|undefined} value */
proto.services.GetDiscussionHistoryResponse.prototype.setMessage = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.GetDiscussionHistoryResponse.prototype.clearMessage = function() {
  this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.GetDiscussionHistoryResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetDiscussionStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetDiscussionStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.GetDiscussionStatisticsRequest.displayName = 'proto.services.GetDiscussionStatisticsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.GetDiscussionStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.GetDiscussionStatisticsRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetDiscussionStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.GetDiscussionStatisticsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetDiscussionStatisticsRequest}
 */
proto.services.GetDiscussionStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetDiscussionStatisticsRequest;
  return proto.services.GetDiscussionStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetDiscussionStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetDiscussionStatisticsRequest}
 */
proto.services.GetDiscussionStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetDiscussionStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetDiscussionStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetDiscussionStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetDiscussionStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.services.GetDiscussionStatisticsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.GetDiscussionStatisticsRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetDiscussionStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetDiscussionStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.GetDiscussionStatisticsResponse.displayName = 'proto.services.GetDiscussionStatisticsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.GetDiscussionStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.GetDiscussionStatisticsResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetDiscussionStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.GetDiscussionStatisticsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      amtMsatSent: jspb.Message.getFieldWithDefault(msg, 1, 0),
      amtMsatReceived: jspb.Message.getFieldWithDefault(msg, 2, 0),
      amtMsatFees: jspb.Message.getFieldWithDefault(msg, 3, 0),
      messagesSent: jspb.Message.getFieldWithDefault(msg, 4, 0),
      messagesReceived: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetDiscussionStatisticsResponse}
 */
proto.services.GetDiscussionStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetDiscussionStatisticsResponse;
  return proto.services.GetDiscussionStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetDiscussionStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetDiscussionStatisticsResponse}
 */
proto.services.GetDiscussionStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmtMsatSent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmtMsatReceived(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmtMsatFees(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMessagesSent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMessagesReceived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetDiscussionStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetDiscussionStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetDiscussionStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetDiscussionStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmtMsatSent();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAmtMsatReceived();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getAmtMsatFees();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getMessagesSent();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getMessagesReceived();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional uint64 amt_msat_sent = 1;
 * @return {number}
 */
proto.services.GetDiscussionStatisticsResponse.prototype.getAmtMsatSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.GetDiscussionStatisticsResponse.prototype.setAmtMsatSent = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 amt_msat_received = 2;
 * @return {number}
 */
proto.services.GetDiscussionStatisticsResponse.prototype.getAmtMsatReceived = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.GetDiscussionStatisticsResponse.prototype.setAmtMsatReceived = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 amt_msat_fees = 3;
 * @return {number}
 */
proto.services.GetDiscussionStatisticsResponse.prototype.getAmtMsatFees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.services.GetDiscussionStatisticsResponse.prototype.setAmtMsatFees = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 messages_sent = 4;
 * @return {number}
 */
proto.services.GetDiscussionStatisticsResponse.prototype.getMessagesSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.services.GetDiscussionStatisticsResponse.prototype.setMessagesSent = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 messages_received = 5;
 * @return {number}
 */
proto.services.GetDiscussionStatisticsResponse.prototype.getMessagesReceived = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.services.GetDiscussionStatisticsResponse.prototype.setMessagesReceived = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.AddDiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.AddDiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.AddDiscussionRequest.displayName = 'proto.services.AddDiscussionRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.AddDiscussionRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.AddDiscussionRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.AddDiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.AddDiscussionRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      discussion: (f = msg.getDiscussion()) && proto.services.DiscussionInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.AddDiscussionRequest}
 */
proto.services.AddDiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.AddDiscussionRequest;
  return proto.services.AddDiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.AddDiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.AddDiscussionRequest}
 */
proto.services.AddDiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.DiscussionInfo;
      reader.readMessage(value,proto.services.DiscussionInfo.deserializeBinaryFromReader);
      msg.setDiscussion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.AddDiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.AddDiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.AddDiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.AddDiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.DiscussionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional DiscussionInfo discussion = 1;
 * @return {?proto.services.DiscussionInfo}
 */
proto.services.AddDiscussionRequest.prototype.getDiscussion = function() {
  return /** @type{?proto.services.DiscussionInfo} */ (
    jspb.Message.getWrapperField(this, proto.services.DiscussionInfo, 1));
};


/** @param {?proto.services.DiscussionInfo|undefined} value */
proto.services.AddDiscussionRequest.prototype.setDiscussion = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.AddDiscussionRequest.prototype.clearDiscussion = function() {
  this.setDiscussion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.AddDiscussionRequest.prototype.hasDiscussion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.AddDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.AddDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.AddDiscussionResponse.displayName = 'proto.services.AddDiscussionResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.AddDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.AddDiscussionResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.AddDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.AddDiscussionResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      discussion: (f = msg.getDiscussion()) && proto.services.DiscussionInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.AddDiscussionResponse}
 */
proto.services.AddDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.AddDiscussionResponse;
  return proto.services.AddDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.AddDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.AddDiscussionResponse}
 */
proto.services.AddDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.DiscussionInfo;
      reader.readMessage(value,proto.services.DiscussionInfo.deserializeBinaryFromReader);
      msg.setDiscussion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.AddDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.AddDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.AddDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.AddDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.DiscussionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional DiscussionInfo discussion = 1;
 * @return {?proto.services.DiscussionInfo}
 */
proto.services.AddDiscussionResponse.prototype.getDiscussion = function() {
  return /** @type{?proto.services.DiscussionInfo} */ (
    jspb.Message.getWrapperField(this, proto.services.DiscussionInfo, 1));
};


/** @param {?proto.services.DiscussionInfo|undefined} value */
proto.services.AddDiscussionResponse.prototype.setDiscussion = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.AddDiscussionResponse.prototype.clearDiscussion = function() {
  this.setDiscussion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.AddDiscussionResponse.prototype.hasDiscussion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.UpdateDiscussionLastReadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.UpdateDiscussionLastReadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.UpdateDiscussionLastReadRequest.displayName = 'proto.services.UpdateDiscussionLastReadRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.UpdateDiscussionLastReadRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.UpdateDiscussionLastReadRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.UpdateDiscussionLastReadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.UpdateDiscussionLastReadRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      discussionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      lastReadMsgId: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.UpdateDiscussionLastReadRequest}
 */
proto.services.UpdateDiscussionLastReadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.UpdateDiscussionLastReadRequest;
  return proto.services.UpdateDiscussionLastReadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.UpdateDiscussionLastReadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.UpdateDiscussionLastReadRequest}
 */
proto.services.UpdateDiscussionLastReadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiscussionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastReadMsgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.UpdateDiscussionLastReadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.UpdateDiscussionLastReadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.UpdateDiscussionLastReadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.UpdateDiscussionLastReadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussionId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLastReadMsgId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 discussion_id = 1;
 * @return {number}
 */
proto.services.UpdateDiscussionLastReadRequest.prototype.getDiscussionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.UpdateDiscussionLastReadRequest.prototype.setDiscussionId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 last_read_msg_id = 2;
 * @return {number}
 */
proto.services.UpdateDiscussionLastReadRequest.prototype.getLastReadMsgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.UpdateDiscussionLastReadRequest.prototype.setLastReadMsgId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.UpdateDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.UpdateDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.UpdateDiscussionResponse.displayName = 'proto.services.UpdateDiscussionResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.UpdateDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.UpdateDiscussionResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.UpdateDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.UpdateDiscussionResponse.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.UpdateDiscussionResponse}
 */
proto.services.UpdateDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.UpdateDiscussionResponse;
  return proto.services.UpdateDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.UpdateDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.UpdateDiscussionResponse}
 */
proto.services.UpdateDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.UpdateDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.UpdateDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.UpdateDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.UpdateDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.RemoveDiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.RemoveDiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.RemoveDiscussionRequest.displayName = 'proto.services.RemoveDiscussionRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.RemoveDiscussionRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.RemoveDiscussionRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.RemoveDiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.RemoveDiscussionRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.RemoveDiscussionRequest}
 */
proto.services.RemoveDiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.RemoveDiscussionRequest;
  return proto.services.RemoveDiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.RemoveDiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.RemoveDiscussionRequest}
 */
proto.services.RemoveDiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.RemoveDiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.RemoveDiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.RemoveDiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.RemoveDiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.services.RemoveDiscussionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.RemoveDiscussionRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.RemoveDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.RemoveDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.RemoveDiscussionResponse.displayName = 'proto.services.RemoveDiscussionResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.RemoveDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.RemoveDiscussionResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.RemoveDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.RemoveDiscussionResponse.toObject = function(includeInstance, msg) {
    var f, obj = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.RemoveDiscussionResponse}
 */
proto.services.RemoveDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.RemoveDiscussionResponse;
  return proto.services.RemoveDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.RemoveDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.RemoveDiscussionResponse}
 */
proto.services.RemoveDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.RemoveDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.RemoveDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.RemoveDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.RemoveDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.CreateInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.CreateInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.CreateInvoiceRequest.displayName = 'proto.services.CreateInvoiceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.CreateInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.CreateInvoiceRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.CreateInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.CreateInvoiceRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      memo: jspb.Message.getFieldWithDefault(msg, 1, ""),
      amtMsat: jspb.Message.getFieldWithDefault(msg, 2, 0),
      expiry: jspb.Message.getFieldWithDefault(msg, 3, 0),
      pb_private: jspb.Message.getFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.CreateInvoiceRequest}
 */
proto.services.CreateInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.CreateInvoiceRequest;
  return proto.services.CreateInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.CreateInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.CreateInvoiceRequest}
 */
proto.services.CreateInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmtMsat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiry(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.CreateInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.CreateInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.CreateInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.CreateInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmtMsat();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getExpiry();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string memo = 1;
 * @return {string}
 */
proto.services.CreateInvoiceRequest.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.CreateInvoiceRequest.prototype.setMemo = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 amt_msat = 2;
 * @return {number}
 */
proto.services.CreateInvoiceRequest.prototype.getAmtMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.CreateInvoiceRequest.prototype.setAmtMsat = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 expiry = 3;
 * @return {number}
 */
proto.services.CreateInvoiceRequest.prototype.getExpiry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.services.CreateInvoiceRequest.prototype.setExpiry = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool private = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.services.CreateInvoiceRequest.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.services.CreateInvoiceRequest.prototype.setPrivate = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.CreateInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.CreateInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.CreateInvoiceResponse.displayName = 'proto.services.CreateInvoiceResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.CreateInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.CreateInvoiceResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.CreateInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.CreateInvoiceResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      invoice: (f = msg.getInvoice()) && proto.services.Invoice.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.CreateInvoiceResponse}
 */
proto.services.CreateInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.CreateInvoiceResponse;
  return proto.services.CreateInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.CreateInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.CreateInvoiceResponse}
 */
proto.services.CreateInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.Invoice;
      reader.readMessage(value,proto.services.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.CreateInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.CreateInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.CreateInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.CreateInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.services.Invoice}
 */
proto.services.CreateInvoiceResponse.prototype.getInvoice = function() {
  return /** @type{?proto.services.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.services.Invoice, 1));
};


/** @param {?proto.services.Invoice|undefined} value */
proto.services.CreateInvoiceResponse.prototype.setInvoice = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.CreateInvoiceResponse.prototype.clearInvoice = function() {
  this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.CreateInvoiceResponse.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.LookupInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.LookupInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.LookupInvoiceRequest.displayName = 'proto.services.LookupInvoiceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.LookupInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.services.LookupInvoiceRequest.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.LookupInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.LookupInvoiceRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      payReq: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.LookupInvoiceRequest}
 */
proto.services.LookupInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.LookupInvoiceRequest;
  return proto.services.LookupInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.LookupInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.LookupInvoiceRequest}
 */
proto.services.LookupInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayReq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.LookupInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.LookupInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.LookupInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.LookupInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayReq();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pay_req = 1;
 * @return {string}
 */
proto.services.LookupInvoiceRequest.prototype.getPayReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.LookupInvoiceRequest.prototype.setPayReq = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.LookupInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.LookupInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.LookupInvoiceResponse.displayName = 'proto.services.LookupInvoiceResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.LookupInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.services.LookupInvoiceResponse.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.LookupInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.LookupInvoiceResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      invoice: (f = msg.getInvoice()) && proto.services.Invoice.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.LookupInvoiceResponse}
 */
proto.services.LookupInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.LookupInvoiceResponse;
  return proto.services.LookupInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.LookupInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.LookupInvoiceResponse}
 */
proto.services.LookupInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.Invoice;
      reader.readMessage(value,proto.services.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.LookupInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.LookupInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.LookupInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.LookupInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.services.Invoice}
 */
proto.services.LookupInvoiceResponse.prototype.getInvoice = function() {
  return /** @type{?proto.services.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.services.Invoice, 1));
};


/** @param {?proto.services.Invoice|undefined} value */
proto.services.LookupInvoiceResponse.prototype.setInvoice = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.services.LookupInvoiceResponse.prototype.clearInvoice = function() {
  this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.LookupInvoiceResponse.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.Invoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.Invoice.repeatedFields_, null);
};
goog.inherits(proto.services.Invoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.Invoice.displayName = 'proto.services.Invoice';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.Invoice.repeatedFields_ = [11,15];


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.Invoice.prototype.toObject = function(opt_includeInstance) {
    return proto.services.Invoice.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.Invoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.Invoice.toObject = function(includeInstance, msg) {
    var f, obj = {
      memo: jspb.Message.getFieldWithDefault(msg, 1, ""),
      hash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      preimage: jspb.Message.getFieldWithDefault(msg, 3, ""),
      paymentRequest: jspb.Message.getFieldWithDefault(msg, 4, ""),
      valueMsat: jspb.Message.getFieldWithDefault(msg, 5, 0),
      amtPaidMsat: jspb.Message.getFieldWithDefault(msg, 6, 0),
      createdTimestamp: (f = msg.getCreatedTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      settledTimestamp: (f = msg.getSettledTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      expiry: jspb.Message.getFieldWithDefault(msg, 9, 0),
      pb_private: jspb.Message.getFieldWithDefault(msg, 10, false),
      routeHintsList: jspb.Message.toObjectList(msg.getRouteHintsList(),
        proto.services.RouteHint.toObject, includeInstance),
      state: jspb.Message.getFieldWithDefault(msg, 12, 0),
      addIndex: jspb.Message.getFieldWithDefault(msg, 13, 0),
      settleIndex: jspb.Message.getFieldWithDefault(msg, 14, 0),
      invoiceHtlcsList: jspb.Message.toObjectList(msg.getInvoiceHtlcsList(),
        proto.services.InvoiceHTLC.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.Invoice}
 */
proto.services.Invoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.Invoice;
  return proto.services.Invoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.Invoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.Invoice}
 */
proto.services.Invoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreimage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentRequest(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setValueMsat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmtPaidMsat(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTimestamp(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSettledTimestamp(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiry(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 11:
      var value = new proto.services.RouteHint;
      reader.readMessage(value,proto.services.RouteHint.deserializeBinaryFromReader);
      msg.addRouteHints(value);
      break;
    case 12:
      var value = /** @type {!proto.services.InvoiceState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAddIndex(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSettleIndex(value);
      break;
    case 15:
      var value = new proto.services.InvoiceHTLC;
      reader.readMessage(value,proto.services.InvoiceHTLC.deserializeBinaryFromReader);
      msg.addInvoiceHtlcs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.Invoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.Invoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.Invoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Invoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreimage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentRequest();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValueMsat();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getAmtPaidMsat();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getCreatedTimestamp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSettledTimestamp();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiry();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getRouteHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.services.RouteHint.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getAddIndex();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getSettleIndex();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getInvoiceHtlcsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.services.InvoiceHTLC.serializeBinaryToWriter
    );
  }
};


/**
 * optional string memo = 1;
 * @return {string}
 */
proto.services.Invoice.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.Invoice.prototype.setMemo = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hash = 2;
 * @return {string}
 */
proto.services.Invoice.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.services.Invoice.prototype.setHash = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string preimage = 3;
 * @return {string}
 */
proto.services.Invoice.prototype.getPreimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.services.Invoice.prototype.setPreimage = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string payment_request = 4;
 * @return {string}
 */
proto.services.Invoice.prototype.getPaymentRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.services.Invoice.prototype.setPaymentRequest = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 value_msat = 5;
 * @return {number}
 */
proto.services.Invoice.prototype.getValueMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.services.Invoice.prototype.setValueMsat = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 amt_paid_msat = 6;
 * @return {number}
 */
proto.services.Invoice.prototype.getAmtPaidMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.services.Invoice.prototype.setAmtPaidMsat = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp created_timestamp = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.Invoice.prototype.getCreatedTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.services.Invoice.prototype.setCreatedTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.services.Invoice.prototype.clearCreatedTimestamp = function() {
  this.setCreatedTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.Invoice.prototype.hasCreatedTimestamp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp settled_timestamp = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.Invoice.prototype.getSettledTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.services.Invoice.prototype.setSettledTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.services.Invoice.prototype.clearSettledTimestamp = function() {
  this.setSettledTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.Invoice.prototype.hasSettledTimestamp = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 expiry = 9;
 * @return {number}
 */
proto.services.Invoice.prototype.getExpiry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.services.Invoice.prototype.setExpiry = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool private = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.services.Invoice.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.services.Invoice.prototype.setPrivate = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated RouteHint route_hints = 11;
 * @return {!Array<!proto.services.RouteHint>}
 */
proto.services.Invoice.prototype.getRouteHintsList = function() {
  return /** @type{!Array<!proto.services.RouteHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.RouteHint, 11));
};


/** @param {!Array<!proto.services.RouteHint>} value */
proto.services.Invoice.prototype.setRouteHintsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.services.RouteHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.RouteHint}
 */
proto.services.Invoice.prototype.addRouteHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.services.RouteHint, opt_index);
};


proto.services.Invoice.prototype.clearRouteHintsList = function() {
  this.setRouteHintsList([]);
};


/**
 * optional InvoiceState state = 12;
 * @return {!proto.services.InvoiceState}
 */
proto.services.Invoice.prototype.getState = function() {
  return /** @type {!proto.services.InvoiceState} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {!proto.services.InvoiceState} value */
proto.services.Invoice.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional uint64 add_index = 13;
 * @return {number}
 */
proto.services.Invoice.prototype.getAddIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.services.Invoice.prototype.setAddIndex = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint64 settle_index = 14;
 * @return {number}
 */
proto.services.Invoice.prototype.getSettleIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.services.Invoice.prototype.setSettleIndex = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated InvoiceHTLC invoice_htlcs = 15;
 * @return {!Array<!proto.services.InvoiceHTLC>}
 */
proto.services.Invoice.prototype.getInvoiceHtlcsList = function() {
  return /** @type{!Array<!proto.services.InvoiceHTLC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.InvoiceHTLC, 15));
};


/** @param {!Array<!proto.services.InvoiceHTLC>} value */
proto.services.Invoice.prototype.setInvoiceHtlcsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.services.InvoiceHTLC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.InvoiceHTLC}
 */
proto.services.Invoice.prototype.addInvoiceHtlcs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.services.InvoiceHTLC, opt_index);
};


proto.services.Invoice.prototype.clearInvoiceHtlcsList = function() {
  this.setInvoiceHtlcsList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.RouteHint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.RouteHint.repeatedFields_, null);
};
goog.inherits(proto.services.RouteHint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.RouteHint.displayName = 'proto.services.RouteHint';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.RouteHint.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.RouteHint.prototype.toObject = function(opt_includeInstance) {
    return proto.services.RouteHint.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.RouteHint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.RouteHint.toObject = function(includeInstance, msg) {
    var f, obj = {
      hopHintsList: jspb.Message.toObjectList(msg.getHopHintsList(),
        proto.services.HopHint.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.RouteHint}
 */
proto.services.RouteHint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.RouteHint;
  return proto.services.RouteHint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.RouteHint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.RouteHint}
 */
proto.services.RouteHint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.HopHint;
      reader.readMessage(value,proto.services.HopHint.deserializeBinaryFromReader);
      msg.addHopHints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.RouteHint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.RouteHint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.RouteHint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.RouteHint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHopHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.HopHint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HopHint hop_hints = 1;
 * @return {!Array<!proto.services.HopHint>}
 */
proto.services.RouteHint.prototype.getHopHintsList = function() {
  return /** @type{!Array<!proto.services.HopHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.HopHint, 1));
};


/** @param {!Array<!proto.services.HopHint>} value */
proto.services.RouteHint.prototype.setHopHintsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.HopHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.HopHint}
 */
proto.services.RouteHint.prototype.addHopHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.HopHint, opt_index);
};


proto.services.RouteHint.prototype.clearHopHintsList = function() {
  this.setHopHintsList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.HopHint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.HopHint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.HopHint.displayName = 'proto.services.HopHint';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.HopHint.prototype.toObject = function(opt_includeInstance) {
    return proto.services.HopHint.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.HopHint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.HopHint.toObject = function(includeInstance, msg) {
    var f, obj = {
      pubkey: jspb.Message.getFieldWithDefault(msg, 1, ""),
      chanId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      feeBaseMsat: jspb.Message.getFieldWithDefault(msg, 3, 0),
      feeRate: jspb.Message.getFieldWithDefault(msg, 4, 0),
      cltvExpiryDelta: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.HopHint}
 */
proto.services.HopHint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.HopHint;
  return proto.services.HopHint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.HopHint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.HopHint}
 */
proto.services.HopHint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubkey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChanId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFeeBaseMsat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFeeRate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCltvExpiryDelta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.HopHint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.HopHint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.HopHint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.HopHint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPubkey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChanId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getFeeBaseMsat();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFeeRate();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCltvExpiryDelta();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string pubkey = 1;
 * @return {string}
 */
proto.services.HopHint.prototype.getPubkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.services.HopHint.prototype.setPubkey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 chan_id = 2;
 * @return {number}
 */
proto.services.HopHint.prototype.getChanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.HopHint.prototype.setChanId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 fee_base_msat = 3;
 * @return {number}
 */
proto.services.HopHint.prototype.getFeeBaseMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.services.HopHint.prototype.setFeeBaseMsat = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 fee_rate = 4;
 * @return {number}
 */
proto.services.HopHint.prototype.getFeeRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.services.HopHint.prototype.setFeeRate = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 cltv_expiry_delta = 5;
 * @return {number}
 */
proto.services.HopHint.prototype.getCltvExpiryDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.services.HopHint.prototype.setCltvExpiryDelta = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.InvoiceHTLC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.InvoiceHTLC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.services.InvoiceHTLC.displayName = 'proto.services.InvoiceHTLC';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
  proto.services.InvoiceHTLC.prototype.toObject = function(opt_includeInstance) {
    return proto.services.InvoiceHTLC.toObject(opt_includeInstance, this);
  };


  /**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.InvoiceHTLC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
  proto.services.InvoiceHTLC.toObject = function(includeInstance, msg) {
    var f, obj = {
      chanId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      amtMsat: jspb.Message.getFieldWithDefault(msg, 2, 0),
      state: jspb.Message.getFieldWithDefault(msg, 3, 0),
      acceptTimestamp: (f = msg.getAcceptTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      resolveTimestamp: (f = msg.getResolveTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      expiryHeight: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.InvoiceHTLC}
 */
proto.services.InvoiceHTLC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.InvoiceHTLC;
  return proto.services.InvoiceHTLC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.InvoiceHTLC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.InvoiceHTLC}
 */
proto.services.InvoiceHTLC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChanId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmtMsat(value);
      break;
    case 3:
      var value = /** @type {!proto.services.InvoiceHTLCState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAcceptTimestamp(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setResolveTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.InvoiceHTLC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.InvoiceHTLC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.InvoiceHTLC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.InvoiceHTLC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAmtMsat();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAcceptTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResolveTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiryHeight();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional uint64 chan_id = 1;
 * @return {number}
 */
proto.services.InvoiceHTLC.prototype.getChanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.services.InvoiceHTLC.prototype.setChanId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 amt_msat = 2;
 * @return {number}
 */
proto.services.InvoiceHTLC.prototype.getAmtMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.services.InvoiceHTLC.prototype.setAmtMsat = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional InvoiceHTLCState state = 3;
 * @return {!proto.services.InvoiceHTLCState}
 */
proto.services.InvoiceHTLC.prototype.getState = function() {
  return /** @type {!proto.services.InvoiceHTLCState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.services.InvoiceHTLCState} value */
proto.services.InvoiceHTLC.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp accept_timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.InvoiceHTLC.prototype.getAcceptTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.services.InvoiceHTLC.prototype.setAcceptTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.services.InvoiceHTLC.prototype.clearAcceptTimestamp = function() {
  this.setAcceptTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.InvoiceHTLC.prototype.hasAcceptTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp resolve_timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.InvoiceHTLC.prototype.getResolveTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.services.InvoiceHTLC.prototype.setResolveTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.services.InvoiceHTLC.prototype.clearResolveTimestamp = function() {
  this.setResolveTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.services.InvoiceHTLC.prototype.hasResolveTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 expiry_height = 6;
 * @return {number}
 */
proto.services.InvoiceHTLC.prototype.getExpiryHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.services.InvoiceHTLC.prototype.setExpiryHeight = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.services.InvoiceState = {
  INVOICE_OPEN: 0,
  INVOICE_ACCEPTED: 1,
  INVOICE_SETTLED: 2,
  INVOICE_CANCELLED: 3
};

/**
 * @enum {number}
 */
proto.services.InvoiceHTLCState = {
  INVOICE_HTLC_ACCEPTED: 0,
  INVOICE_HTLC_SETTLED: 1,
  INVOICE_HTLC_CANCELLED: 2
};

goog.object.extend(exports, proto.services);
